import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 开发环境
    // baseURL: 'http://localhost:7788/',
    // 生产环境
    baseURL: 'http://api.metabyte.top:7788/',
    // currentMenuName: null
  },
  mutations: {
    // setCurrentTabName(state, tabName) {
    //   state.currentTabName = tabName;
    // },
  },
})
